<template>
  <div>
    <div class="banner">
      <div class="container">
        <div>
          <h2 class="slogan">
            <span class="keyword">ShuangzhaoDB </span><span>为实时而生</span>
          </h2>
          <p class="desc">一个为高速处理数据而重新设计的关系型内存数据库</p>
        </div>
      </div>
    </div>
    <div class="top container">
      <el-row class="abstract">
        <el-col :xs="24" :sm="24" :md="16" :lg="16">
          <el-card>
            <div>
              <div class="title">什么是ShuangzhaoDB ?</div>
              <p>
                ShuangzhaoDB
                是分布式内存数据库，最大的特点就是快，能够一站式实现数据加载、计算、存储的需求，降低总成本并简化架构复杂性，全内存计算通常存放G~T级数据量，多用于热数据或温数据处理及存储。
              </p>
            </div>
            <el-button type="text" @click="$router.push({ name: 'product' })">
              了解更多 <i class="el-icon-arrow-right" />
            </el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8">
          <el-card>
            <div>
              <div class="title">案例</div>
              <p>
                ShuangzhaoDB
                拥有大吞吐量，超低延时，高可靠性等的特点，适用于各大型系统及应用；如：在快速预警，实时数仓，实时决策等。
              </p>
            </div>
            <el-button type="text" @click="$router.push({ name: 'case' })">
              了解更多 <i class="el-icon-arrow-right" />
            </el-button>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="chr">
        <el-col
          :xs="24"
          :sm="24"
          :md="8"
          :lg="8"
          v-for="item in chrList"
          :key="item.id"
        >
          <div class="header">
            <i class="iconfont" :class="item.icon" />
            <div class="title">{{ item.title }}</div>
          </div>
          <p>{{ item.desc }}</p>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="ability">
        <h2 class="title">助力产业革命</h2>
        <p class="desc">
          城市数字化转型，催生了新的数据管理方式，ShuangzhaoDB
          专为当今庞大的数据量和复杂性而构建
        </p>
        <p class="desc">助力企业在5G，物联网，金融技术应用的蓬勃发展</p>
        <el-row class="ability-items">
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            v-for="item in abilityList"
            :key="item.id"
          >
            <el-card>
              <i class="iconfont" :class="item.icon" />
              <div>
                <div class="title">{{ item.title }}</div>
                <p>{{ item.desc }}</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row class="fluid">
      <el-col :xs="8" :sm="8" :md="4" :lg="4" class="item">
        <div class="data">{{ fluid.response }}%</div>
        <div class="desc">超低延迟响应</div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="4" :lg="4" class="item">
        <div class="data">{{ fluid.transaction }}%</div>
        <div class="desc">终止欺诈交易</div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="4" :lg="4" class="item">
        <div class="data">{{ fluid.capacity }}x</div>
        <div class="desc">倍吞吐能力</div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="4" :lg="4" class="item">
        <div class="data">{{ fluid.attack }}%</div>
        <div class="desc">阻止DDOS攻击</div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="4" :lg="4" class="item">
        <div class="data">{{ fluid.advertisement }}ms</div>
        <div class="desc">检测恶意广告</div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="4" :lg="4" class="item">
        <div class="data">{{ fluid.wastage }}%</div>
        <div class="desc">减少收入流失</div>
      </el-col>
    </el-row>
    <div class="platforms container">
      <h2 class="title">多平台兼容</h2>
      <p class="desc">ShuangzhaoDB兼容多国产平台和操作系统</p>
      <ul class="logo-list">
        <li v-for="item in platformList" :key="item.id">
          <img :src="item.imgUrl" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      chrList: [
        {
          id: 1,
          icon: "icon-yunjisuan-",
          title: "内存计算",
          desc: `ShuangzhaoDB是完全的内存数据库，所有计算都在内存中完成，数据都存放在内存中，保证了计算和数据在最接近的地方。`,
        },
        {
          id: 2,
          icon: "icon-ahasyingyonggaokeyong",
          title: "高可用",
          desc: `ShuangzhaoDB支持多个集群组成多活或主备集群，如：两地三中心、同城双活等等。多活集群在备份的同时，每个集群还同时提供读写操作，保证安全的同时，提高了吞吐量。`,
        },
        {
          id: 3,
          icon: "icon-xianxinghuigui",
          title: "线性扩容",
          desc: `ShuangzhaoDB性能会随着集群节点数量的增加而线性增长，随着应用端性能需求的增长，集群可以简短地增加节点数量来解决。`,
        },
      ],
      abilityList: [
        {
          id: 1,
          icon: "icon-jishujiaoyijiagepingguxitong",
          title: "在线交易",
          desc: `分布式强ACID保证，确保任何情况下。您的在线交易数据的完整性和一致性。`,
        },
        {
          id: 2,
          icon: "icon-zhongdajuecegongkai",
          title: "决策控制",
          desc: `快速、大规模地对各种数据进行智能化处理，可以轻松满足各种场景下的人工规则+机器学习模型的复杂性决策需求。`,
        },
        {
          id: 3,
          icon: "icon-shangye-1",
          title: "商业模式",
          desc: `用 ShuangzhaoDB 构建功能强大的应用程序，显著提高ARPU，通过给应用程序带来更低延迟来实现商业价值。`,
        },
        {
          id: 4,
          icon: "icon-anquan",
          title: "反欺诈检测",
          desc: `实时主动反欺诈检测，从而降低风险并使您领先于欺诈者三步。`,
        },
      ],
      fluid: {
        response: 0,
        transaction: 0,
        capacity: 0,
        attack: 0,
        advertisement: 0,
        wastage: 0,
      },
      platformList: [
        { id: 1, imgUrl: require(`@/assets/images/platform-logo1.png`) },
        { id: 2, imgUrl: require(`@/assets/images/platform-logo2.png`) },
        { id: 3, imgUrl: require(`@/assets/images/platform-logo3.png`) },
        { id: 4, imgUrl: require(`@/assets/images/platform-logo4.png`) },
        { id: 5, imgUrl: require(`@/assets/images/platform-logo5.png`) },
        { id: 6, imgUrl: require(`@/assets/images/platform-logo6.png`) },
        { id: 7, imgUrl: require(`@/assets/images/platform-logo7.png`) },
        { id: 8, imgUrl: require(`@/assets/images/platform-logo8.png`) },
        { id: 9, imgUrl: require(`@/assets/images/platform-logo9.png`) },
      ],
    };
  },
  created() {
    this.increaseCount(253, 15, "response");
    this.increaseCount(83, 5, "transaction");
    this.increaseCount(10, 1, "capacity");
    this.increaseCount(100, 5, "attack");
    this.increaseCount(10, 1, "advertisement");
    this.increaseCount(25, 2, "wastage");
  },
  methods: {
    increaseCount(targetNum, span, prop) {
      let index = 0;
      if (index < targetNum) {
        let timer = setInterval(() => {
          index += span;
          if (index > targetNum) {
            clearInterval(timer);
            index = targetNum;
          }
          this.fluid[prop] = index;
        }, 100);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.banner {
  padding-top: 100px;
  background: url("~@/assets/images/grid.jpg") no-repeat top / cover;
  color: #404040;
  height: 500px;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .slogan {
    font-size: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .banner .slogan {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 991px) {
  .banner {
    height: 280px;
    padding: 30px 15px 0;
    .slogan {
      font-size: 30px;
      margin-bottom: 15px;
    }
    .desc {
      font-size: 14px;
      color: #404040;
    }
  }
}

.top {
  .abstract {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -200px;
    .el-card {
      transition: all 0.5s linear;
      /deep/ .el-card__body {
        padding: 30px 48px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        .title {
          font-size: 24px;
          line-height: 40px;
          padding: 20px 0;
        }
        .el-button.el-button--text {
          color: #404040 !important;
          &:hover {
            color: @themeRed !important;
          }
        }
      }
    }
  }
  @media screen and(max-width:991px) {
    .abstract {
      margin-top: -50px;
      .el-col {
        margin-top: 20px;
        &:first-of-type {
          margin-bottom: 20px;
        }

        /deep/ .el-card__body {
          padding: 15px;
          .title {
            font-size: 22px;
            padding: 10px 0;
          }
        }
      }
    }
  }
  @media screen and(min-width:992px) {
    .abstract .el-col {
      &:first-of-type {
        margin-right: 50px;
      }
      &.el-col-md-16 {
        width: calc(66.66% - 50px);
      }
      .el-card {
        height: 320px;
        &:hover {
          transform: translateY(-15px);
        }
      }
    }
  }
  .chr {
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    .el-col {
      display: flex;
      flex-direction: column;
      padding-right: 30px;
      align-items: center;
      color: #404040;
      &:last-of-type {
        padding-right: 0;
      }
      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .iconfont {
        font-size: 80px;
        color: #bfbfbf;
      }
      .title {
        line-height: 50px;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
    @media screen and(max-width:991px) {
      .el-col {
        padding-right: 0;
        .header {
          flex-direction: row;
          .iconfont {
            font-size: 40px;
          }

          .title {
            margin-bottom: 0;
            padding: 15px;
            font-size: 18px;
          }
        }
        p {
          padding: 0 20px;
        }
        &:last-of-type .iconfont {
          font-size: 30px;
        }
      }
    }
  }
  .el-divider {
    margin: 0;
  }
  .ability {
    padding: 100px 0;
    .title {
      line-height: 40px;
      font-weight: 500;
      padding-bottom: 30px;
      text-align: center;
    }
    .desc {
      padding: 0 15px 15px;
      text-align: center;
    }
    .ability-items {
      display: flex;
      flex-wrap: wrap;
      .el-col {
        margin-top: 30px;
        .el-card {
          /deep/ .el-card__body {
            display: flex;
            padding: 30px 20px;
            text-align: left;
            .iconfont {
              padding: 20px;
              font-size: 50px;
              color: @themeBlue;
            }
            .title {
              font-size: 20px;
              font-weight: 500;
              line-height: 40px;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    .ability .ability-items .el-col {
      width: calc(50% - 25px);
      &:nth-of-type(odd) {
        margin-right: 50px;
      }
      .el-card {
        height: 200px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .ability {
      padding: 50px 0;
      .title {
        font-size: 22px;
      }
      .desc {
        text-align: justify;
      }
      .ability-items {
        .el-col {
          margin-top: 20px;
          :first-of-type {
            margin-top: 0;
          }
          /deep/ .el-card .el-card__body {
            align-items: center;
            padding: 15px;
            .iconfont {
              padding: 0 15px 0 0;
            }
            .title {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.fluid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px;
  background: url("~@/assets/images/fluid.png");
  color: #fff;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .data {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 991px) {
  .fluid {
    align-items: flex-start;
    padding: 20px;
    .item {
      padding: 0 8px;
      .data {
        font-size: 26px;
        margin-bottom: 0;
      }
      .desc {
        font-size: 12px;
      }
    }
  }
}

.platforms {
  text-align: center;
  .title {
    padding: 40px;
    line-height: 40px;
    font-weight: 500;
  }
  .desc {
    padding: 0 10px;
    text-align: center;
  }
  .logo-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0;
    li {
      width: 20%;
      padding: 20px;
      img {
        width: 60%;
      }
      &:last-of-type img {
        width: 90%;
      }
      &:nth-last-of-type(2) img,
      &:nth-last-of-type(4) img {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .platforms {
    .title {
      padding: 20px;
      font-size: 22px;
    }
    .logo-list {
      margin: 0;
      li {
        width: 33.33%;
        padding: 10px;
        img {
          width: 80%;
        }
        &:nth-of-type(4) img {
          width: 60%;
        }
        &:nth-last-of-type(2) img {
          width: 120%;
        }
      }
    }
  }
}
</style>
